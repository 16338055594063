<template>
    <main ref="resumen" id="resumen">
        <div class="card mt-2 shadow-none" v-if="isLoading">
            <div class="card-body">
                <content-placeholders :rounded="true">
                    <content-placeholders-heading :img="false" />
                    <content-placeholders-text :lines="3" />
                </content-placeholders>
            </div>
        </div>
        <div v-else>
            <div class="py-2">
                <motivoConsultaShow :idHistoria="idHistoria" :data="resumen.motivo_consulta"></motivoConsultaShow>
            </div>
            <div class="py-2">
                <examenFisicoShow :idHistoria="idHistoria" :examenFisico="resumen.examen_fisico"></examenFisicoShow>
            </div>
            <div class="py-2">
                <exploracionFisicaShow :idHistoria="idHistoria" :data="resumen.exploracion_fisica">
                </exploracionFisicaShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.ordenes">
                <ordenClinicaShow :idHistoria="idHistoria" :data="resumen.ordenes"></ordenClinicaShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.formulas">
                <formulaMedicaShow :idHistoria="idHistoria" :data="resumen.formulas"></formulaMedicaShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.impresion_diagnostica">
                <impresionDxShow :idHistoria="idHistoria" :data="resumen.impresion_diagnostica"></impresionDxShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.reporte_examenes">
                <reporteExamenesShow :idHistoria="idHistoria" :data="resumen.reporte_examenes"></reporteExamenesShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.situaciones_personales">
                <situacionesPersonalesShow :idHistoria="idHistoria" :idUsuario="idUsuario"
                    :data="resumen.situaciones_personales"></situacionesPersonalesShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.fragmingam">
                <fragmingamShow :idHistoria="idHistoria" :data="resumen.fragmingam"></fragmingamShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.findrisc">
                <finnisRiskShow :idHistoria="idHistoria" :data="resumen.findrisc"></finnisRiskShow>
            </div>
        </div>
    </main>

</template>

<script>
import motivoConsultaShow from "../../base/motivoConsulta/motivoConsultaShow";
import examenFisicoShow from "../../base/examenFisico/examenFisicoShow";
import ordenClinicaShow from "../../base/orden/ordenClinicaShow";
import formulaMedicaShow from "../../base/formulaMedica/formulaMedicaShow";
import impresionDxShow from "../../base/impresionDX/impresionDxShow";
import exploracionFisicaShow from "../../base/examenFisico/exploracionFisicaShow";
import reporteExamenesShow from "../../base/baseReporteExamenesAdolescente/reporteExamenesShow";
import situacionesPersonalesShow from "../../base/baseSituacionesPersonales/situacionesPersonalesShow";
import finnisRiskShow from "../../base/baseFinnisRisk/finnisRiskShow";
import fragmingamShow from "../../base/baseFragmingam/fragmingamShow";
import historiaClinicaService from "../../../../services/historiaClinicaService";

export default {
    props: ['idHistoria', 'idUsuario'],
    components: {
        motivoConsultaShow,
        examenFisicoShow,
        ordenClinicaShow,
        formulaMedicaShow,
        impresionDxShow,
        exploracionFisicaShow,
        reporteExamenesShow, situacionesPersonalesShow, finnisRiskShow, fragmingamShow
    },
    data() {
        return {
            resumen: {},
            isLoading: true
        };
    },

    async created() {
        this.isLoading = true;
        const response = await historiaClinicaService.resumenHc(this.idHistoria);
        this.resumen = response.data;
        this.isLoading = false;
    },
}
</script>

<style scoped>
.resumen-container {
    height: 300px;
    overflow-y: scroll
}
</style>